input[type="date"]:not(.has-value):before{
    color: #6e6e6e;
    content: attr(placeholder);

  }
  .css-13cymwt-control{
    min-width: 100px;
  }
  .css-t3ipsp-control{
    min-width: 100px !important;
  }